body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}

header {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

nav a {
  margin: 0 15px;
  color: white;
  text-decoration: none;
}

section {
  padding: 20px;
  margin: 20px;
  background-color: white;
  border-radius: 8px;
}

form input, form textarea {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  max-width: 400px;
}

button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}
